<template>
    <v-sheet
            class="mb-5 mx-4"
    >
        <v-row class="mb-4 pa-5">
            <v-icon small class="mr-3">mdi-calendar</v-icon>
            <div class="mr-3 text-lg-h6 text-sm-subtitle-1 font-weight-light" v-text="date(value.post_date)"/>
        </v-row>
        <v-row>
            <v-chip
                    v-for="(tag, i) in value.tags"
                    label
                    :key="`tags-${i}`"
                    class="mx-2 mb-2 text-lowercase"
                    color="grey darken-3"
                    text-color="white"
                    small

                    @click.stop=""
            >
                #{{getTag(tag)}}
            </v-chip>
        </v-row>
        <v-row class="text-lg-h6 text-sm-subtitle-1 font-weight-light mb-5 post__body text-lg-justify pa-5">
        <div style="width: 100%"
                v-html="value.acf.post_content[currentLang]"
        />
        </v-row>
        <v-row
                class="text-lg-h6 text-sm-subtitle-1 font-weight-light mb-5 mx-3"
        >
            <span><a href="">#Jemeinsam</a> {{$t('news.togetherWith')}}</span>
            <span>
            <a
                    class="mx-1"
                    v-for="(sponsor, i) in sponsors"
                    :key="`sponsors-${i}`"
                    :href="`#/sponsors/#${sponsor.acf.sponsor_name}`"
            >
                {{sponsor.acf.sponsor_name}}
            </a>
                </span>
        </v-row>
    </v-sheet>
</template>

<script>
    import i18n from '@//plugins/i18n'
    import { mapState } from 'vuex'

    export default {
        props: {
            dense: {
                type: Boolean,
                default: false
            },
            value: {
                type: Object,
                default: () => ({})
            }
        },

        computed: {
            ...mapState('app',['sponsors','tags']),
            currentLang () {
                return i18n.locale
            }
        },
        methods: {
            date (v){
                let dateTime = v.split(" ")
                let date = dateTime[0].split("-")
                date.reverse()
                let res = date.join(".")
                return res
            },
            getTag(id){
                const tag = this.tags.find(tag => tag.id === id)
                return tag.name
            }
        }
    }
</script>

<style>
    img {
        max-width:95%;
        height: auto;
        margin: auto;
        padding: 5px;
        display: block;
    }
    iframe {
      max-width:100%;
      margin: auto;
      padding: 5px;
      display: block;
    }
</style>
